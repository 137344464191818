<template>
<div ref="modalPortConfirmCheckIn" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortCheckInConfirmModalOpen ? 1 : 0, bottom: modalPortCheckInConfirmHeight + 'px'}" :class="{active: isPortCheckInConfirmModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<CheckInIcon style="width: 25px; height: auto;" class="icon checkin-icon" />
			<span>Check-in</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="togglePortCheckInModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap-alt-small">
		<div class="info-card-pass">
			<div class="info-card-head">
				<p class="sub">You're checking in at</p>
				<p class="big">{{ port.name }}, {{ port.country }}</p>
			</div>
			<div class="info-card no-title">
				<div class="info-card-wrap">
					<div class="row">
						<div class="col full">
							<span class="eyebrow">Contact Information</span>
                            <p v-if="port.address" v-html="port.address" class="p-strong">{{ port.address }}</p>
                            <p v-else class="p-strong">{{ port.location }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<span class="eyebrow">Date</span>
                            <p class="p-strong">{{ date | moment('Do MMMM YYYY') }}</p>
						</div>
						<div class="col">
							<span class="eyebrow">Time</span>
                            <p class="p-strong">{{ date | moment('timezone', port.timezone, 'hh:mm A') }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col full">
							<span class="eyebrow">Name</span>
							<p class="p-strong">{{ user }}</p>
						</div>
					</div>
				</div>
				<Button icon="TickIcon" class="section-gap none-bottom btn btn-orange btn-tick btn-square btn-icon btn-icon-right" text="Confirm Check-in" @click.native="checkIn"></Button>
			</div>
		</div><!-- info pass -->
	</div>
	<a class="text-link text-link-grey" @click="togglePortCheckInModal">I don't want to check-in right now &raquo;</a>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import CheckInIcon from '@/assets/check-in.svg';
import PortService from '@/services/PortService';
export default {
name: 'PortCheckInConfirmOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
            date: new Date(),
			modalPortCheckInConfirmHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		CheckInIcon
	},
	computed: {
        isPortCheckInConfirmModalOpen () {
            return this.$store.state.isPortCheckInConfirmModalOpen;
        },
        port () {
            return this.$store.state.port;
        },
        user () {
            return this.$store.state.user;
        },
		matchHeightModal () {
			return '-' + this.$refs.modalPortConfirmCheckIn.clientHeight;
		},
        portUrl () {
            return process.env.VUE_APP_URL + this.$route.fullPath;
        }
	},
	methods: {
        togglePortCheckInModal () {
        	this.$store.commit('PORT_CHECK_IN_CONFIRM_TOGGLE');
            this.modalPortCheckInConfirmHeight = '-' + this.$refs.modalPortConfirmCheckIn.clientHeight;
        },
        checkIn () {
            PortService.checkIn(this.port.id).then((response) => {
                this.$store.commit('SET_CHECK_IN', response.data);
                console.log(response);
            });
        }
    },
    mounted () {
		this.modalPortCheckInConfirmHeight = '-1000';
        setInterval(() => {
            this.date = new Date();
        }, 30000);
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $orange_dark;
				}
			}
			span {
				color: $orange_dark;
			}
		}
	}
</style>
