<template>
<div ref="modalPortCheckInSuccess" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortCheckInSuccessModalOpen ? 1 : 0, bottom: modalPortCheckInSuccessHeight + 'px'}" :class="{active: isPortCheckInSuccessModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<CheckInIcon style="width: 25px; height: auto;" class="icon checkin-icon" />
			<span>Check-in</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortCheckInSuccessModal()" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block">
		<SuccessIcon class="success-icon" style="width: 90px; height: 90px;" />
		<h3>You're Checked-in!</h3>
	</div>
	<div v-if="checkIn.network_name && checkIn.network_password && checkIn.network_access" class="section-gap">
		<div class="info-card">
			<span class="eyebrow">Centre Wi-Fi Access</span>
			<div class="info-card-wrap">
				<div class="row">
					<p class="p-strong col">Network Name</p>
					<p class="col">{{ checkIn.network_name }}</p>
				</div>
				<div class="row">
					<p class="p-strong col">Network Password</p>
					<p class="col">{{ checkIn.network_password }}</p>
				</div>
				<div class="row">
					<p class="p-strong col">Network Access</p>
					<p class="col">{{ checkIn.network_access }}</p>
				</div>
			</div>
		</div><!-- info card -->
	</div>
    <br v-else>
	<Button icon="AnchorIcon" class="none-bottom btn btn-blue btn-tick btn-icon btn-icon-right" text="Return to Port" @click.native="closePortCheckInSuccessModal"></Button>
	<Button icon="HomeIcon" class="none-bottom btn btn-grey btn-tick btn-icon btn-icon-right" text="Return Home" @click.native="closePortCheckInSuccessModal" routeName="/dashboard"></Button>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import CheckInIcon from '@/assets/check-in.svg';
import SuccessIcon from '@/assets/success-filled.svg';
export default {
name: 'PortCheckInOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalPortCheckInSuccessHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		CheckInIcon,
		SuccessIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortCheckInSuccess.clientHeight;
		},
        isPortCheckInSuccessModalOpen () {
            return this.$store.state.isPortCheckInSuccessModalOpen;
        },
        checkIn () {
            return this.$store.state.checkIn;
        }
	},
	methods: {
        closePortCheckInSuccessModal () {
        	this.$store.state.isPortCheckInSuccessModalOpen = false;
        }
    },
    mounted () {
		this.modalPortCheckInSuccessHeight = this.matchHeightModal;
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $orange_dark;
				}
			}
			span {
				color: $orange_dark;
			}
		}
	}
	.modal-message-block {
		svg path {
			fill: $orange_base;
		}
	}
</style>
