import * as API from '@/services/API';

export default {
    getPorts () {
        return API.apiClient.get('/ports');
    },
    getPort (id) {
        return API.apiClient.get(`/ports/${id}`);
    },
    search (query) {
        return API.apiClient.get(`/ports/search/${query}`);
    },
    searchActive (query) {
        return API.apiClient.get(`/ports/search-active/${query}`);
    },
    checkIn (id) {
        return API.apiClient.get(`/ports/${id}/check-in`);
    }
};
